<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-header class="shadow page-header__wrap">
      <div class="flex">
        <div class="backBtn" @click="handleReturn">
          <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
          <span>返回</span>
        </div>
        <div class="title">
          <span class="site">当前位置：</span>
          {{ $route.meta.title }}
          <span> > </span>
          查看明细
        </div>
      </div>
    </el-header>

    <div class="main">
      <div class="mainBox">
        <div class="itemTime">基础信息</div>
        <el-row style="color:#606266;margin-bottom:20px;font-size:14px;font-weight:bold;">
          <el-col :span="6">
            <div><span style="color:#909399;">下单分类：</span>{{curRow.cate_name}}</div>
          </el-col>
          <el-col :span="8">
            <div><span style="color:#909399;">商品名称：</span>{{curRow.norm_name}}</div>
          </el-col>
          <el-col :span="4">
            <div><span style="color:#909399;">单位：</span>{{curRow.unit_name}}</div>
          </el-col>
          <el-col :span="6">
            <div>
              <span style="color:#909399;">规格：</span>
              <span v-if="curRow.spec_names.length > 1">存在多个商品规格</span>
              <span v-else>{{curRow.spec_names[0]}}</span>
            </div>
          </el-col>
        </el-row>

        <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
          <el-form-item>
            <el-radio-group v-model="table.params.type" @change="onSearch">
              <el-radio :label="1">只看入库</el-radio>
              <el-radio :label="2">只看出库</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="table.params.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              class="selDaterangeWidth"
              :clearable="true"
              @change="onSearch">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="供应商/采购员" prop="supplier_arr">
            <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
              <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户食堂" prop="address_id">
            <el-select v-model="table.params.address_id" placeholder="请选择" filterable @focus="getStore" clearable @change="onSearch">
              <el-option v-for="item in storeArr" :label="item.name"  :value="item.id" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
            <el-button icon="el-icon-refresh-right" @click="resetSel">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table
          :data="tableData"
          style="width: 100%;margin-top:10px;"
          :header-cell-style="{background:'#f5f5f5'}"
          size="mini"
          border>
          <el-table-column
            prop="good_name"
            label="商品名称">
          </el-table-column>
          <el-table-column
            prop="type"
            label="出入库类型">
            <template slot-scope="{row}">
              <span>{{row.type == 1 ? '入库' : '出库'}}</span> - 
              <span>{{statusArr[row.type][row.out_type]}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="source"
            label="来源/去向">
            <template slot-scope="{row}">
              <span v-if="!!row.source">{{row.source}}</span>
              <span v-else>自主创建入库</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="change_quantity"
            label="数量">
            <template slot-scope="{row}">
              <span>{{row.change_quantity}}{{curRow.unit_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_price"
            label="单价">
            <template slot-scope="{row}">
              <span>¥{{row.unit_price}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="金额">
            <template slot-scope="{row}">
              <span>¥{{row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="surplus_quantity"
            label="剩余库存">
            <template slot-scope="{row}">
              <span>{{row.surplus_quantity}}{{curRow.unit_name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间">
          </el-table-column>
          <el-table-column
            prop="account_name"
            label="操作人">
          </el-table-column>
        </el-table>

        <div class="pageWrap" v-if="table.total > 10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="table.params.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="table.params.count"
            layout="total, sizes, prev, pager, next, jumper"
            :total="table.total">
          </el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { mixinTable } from "@/mixins/table.js";
  export default {
    name: 'StoreStockDetail',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        table: {
          params: {
            type: '', //类型 1:入库 2:出库
            date: [], //日期时间
            stash_id: '', //仓库id
            start: '',
            end: '',
            source: '',
            supplier_id: '',
            merchant_id: '',
            address_id: '',
            page:1,
            count:10,
          },
          total: 0,
        },
        curRow: {
          spec_names: [],
        },
        tableData: [],
        statusArr:[
          [],
          ['','采购入库','创建入库','转换单位','退货入库','盘点入库','采购退货','修改增加采购','减少分拣'],
          ['','正常出库','环保处理','转换单位','退货入库','盘点报损','采购退货','修改减少采购','增加分拣']
        ],
        supplierArr: [], // 供应商/采购员
        merchantArr: [], // 客户列表
        storeArr: [],
      }
    },
    methods: {
      getDetail(row) {
        this.curRow = row;
        this.table.params.date = row.date;
        this.table.params.stash_id = row.stash_id;
        this.getTable();
      },
      onSearch() {
        this.table.params.page = 1;
        this.getTable()
      },
      getTable() {
        let _params = {
          ...this.table.params,
          goods_name: this.curRow.norm_name, // 标准名称
          unit_name: this.curRow.unit_name, //单位
          uni_spec: this.curRow.uni_spec, //统一标识
        }
        let _date = this.table.params.date;
        if(!!_date && _date.length > 0) {
          _params.start = !!_date ? _date[0] : '';
          _params.end = !!_date ? _date[1] : '';
        } 
        delete _params.date;
        this.isChange = true
        this.$http.get('/admin/inventory/log', { params:_params}).then(res => {
          if(res.code == 1) {
            this.tableData = res.data.list; // 商品信息
            this.table.total = res.data.total;
          }
        })
      },
      // 重置查询条件
      resetSel() {
        this.table.params = {
          type: '',
          time: '',
          page:1,
          count:10,
        }
        let data = {
          ...this.table.params,
          ...this.curRow,
        }
        this.getDetail(data)
      },
      reset(done) {
        this.isChange = false;
        this.table.params.page = 1;
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.tableData = [];
      },
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}
</style>
